<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="clients"
      class="elevation-0"
      disable-pagination
      hide-default-footer >
      <template v-slot:no-data>
        Aucun client
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">clients </v-toolbar-title>
          <text-input prepend-inner-icon="mdi-magnify" @keyup="handleSearch" class="ml-5 mt-5" id="search" v-model="search" placeholder="Nom du client"/>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <template v-slot:activator="{ on, attrs }">
              <Button v-bind="attrs" v-on="on" class="mb-2" action_name="Ajouter" color="primary" text @clicked="openAddDialog"/>
            </template>
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <text-input v-model="editedItem.name" label="Nom du client*" id="name" :rules="requiredRules" />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button action_name="Continuer" color="#86CC26" @clicked="save(true)" v-if="!showEditButton" :loading="loading" />
                <Button action_name="Ajouter" color="#11887E" text @clicked="save(false)" v-if="!showEditButton" :loading="loadingAdd" />
                <Button action_name="Modifier" color="#11887E" text @clicked="update(false)" v-if="showEditButton" :loading="loadingEdit"/>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { Button, TextInput } from "@/components/base";
import { getAllClients, addClient, updateClient, searchClient } from "@/services/clients.api";
import { infinityScrollMixin } from "@/utils/infinityScrollMixin";
export default {
  components: { Button, TextInput },
  mixins: [infinityScrollMixin],
  data: () => ({
      search:"",
      loading:false,
      loadingAdd:false,
    loadingEdit:false,
    isValid: true,
    showEditButton: false,
    dialog: false,
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id"
      },
      { text: "Nom", value: "name" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    clients: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    pageNumber: 1,
    last_page:0
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau client" : "Modifier client";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    endOfTheScreen(endOfTheScreen) {
    if (endOfTheScreen === true && this.pageNumber < this.last_page) {
      this.pageNumber += 1;
      getAllClients(this.pageNumber).then(response => {
        this.clients =[...this.clients, ...response.data.data];
        this.last_page = response.data.last_page
      });
    }
  },
  },
  created() {
    this.initialize();
  },
  methods: {
    handleSearch() {
      if (this.search !== "") {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          searchClient(this.search).then(response => {
            this.clients = response.data;
          })
        }, 1000);
      } else {
        getAllClients(this.pageNumber).then(response => { this.clients = response.data.data; this.last_page = response.data.last_page});
      }
    },
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.dialog = true;
    },
    initialize() {
      getAllClients(this.pageNumber).then(response => { this.clients = response.data.data; this.last_page = response.data.last_page});
    },
    close() {
      this.agentDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showEditButton = false;
      });
    },
    update() {
      this.loadingEdit= true;
      updateClient(this.editedItem).then(() => {
        this.loadingEdit= false;
        this.showEditButton = false;
        Object.assign(this.clients[this.editedIndex], this.editedItem);
        this.close();
        this.$swal.fire({ title: "Client modifier avec succès" });
      });
    },
    editItem(item) {
      this.showEditButton = true;
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      dialogStat ? (this.loading = true) : (this.loadingAdd = true);

      addClient(this.editedItem).then(response => {
        dialogStat ? (this.loading = false) : (this.loadingAdd = false);
        this.editedItem.id = response.data.id;
        this.editedItem.status = response.data.status;
        this.clients.push(this.editedItem);
        this.close();
        this.$refs.form.reset();
        this.dialog = dialogStat;
        this.$swal.fire({ title: "Client ajouté avec succès" });
      });
    }
  }
};
</script>
<style scoped></style>
